.xcn-card{
    min-width: 10rem;
    border-radius: 0.25rem !important;
    padding: 1rem !important;
    border:none !important;
}
.xcn-card .card-header{
    background-color: white;
    border:none;
    font-weight: bold;
    padding: 0.5rem;
    
}
.xcn-container{
    /* margin-left: 15rem !important; */
    /* margin-right: 3rem !important; */
}
