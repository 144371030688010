@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.App {
  background: #F3F3F6;
  min-height: 100vh;
  min-width: 100vw;
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.xcn-login-bg{
  background: rgb(115,100,241);
  background: radial-gradient(circle, rgba(115,100,241,1) 0%, rgba(148,186,233,1) 100%);
  height: 100vh;
}

.xcn-login-header{
  padding-top: 5rem;
}

.xcn-input{
  text-align: left;
}

.xcn-fw-100{
  font-weight: 100 !important;
}

.xcn-fw-200{
  font-weight: 200 !important;
}

.xcn-fw-300{
  font-weight: 300 !important;
}

.xcn-fw-400{
  font-weight: 400 !important;
}

.xcn-fw-500{
  font-weight: 500 !important;
}

.xcn-fw-600{
  font-weight: 600 !important;
}

.xcn-text-14{
  font-size: 0.9rem !important;
}

.xcn-text-12{
  font-size: 0.8rem !important;
}
.xcn-text-10{
  font-size: 0.7rem !important;
}

.xcn-text-16{
  font-size: 0.95rem !important;
}

.xcn-text-18{
  font-size: 1.15rem !important;
}
.xcn-text-xl{
  font-size: 3rem !important;
}

.xcn-link-pointer{
  cursor: pointer;
}

.xcn-table-tr{
  height: 5rem;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.mr-1{
  margin-right: 1rem;
}

.xcn-form-control{
  border-radius: 0.5rem;
  height: 2.5rem;
  font-size: 0.9rem !important;
}