.xcn-dashboard-sub-nav{
    background-color: #035CB5;
    height: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    text-align: left;
    width: 6rem;
    transition: all 0.5s cubic-bezier(0.13, 0.88, 1, 1);
}
.xcn-dash-logo{
    height: 2rem;
}
.xcn-nav-selected{
    border-right: 5px solid white;
}

.xcn-sidebar-links{
    padding: 0.65rem 0rem;
}
