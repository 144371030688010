.xcd-container{
    margin-left: 12rem !important;
}


.xcd-container-large{
    min-width: 90%;
}
.xcn-container{
    /* margin-left: 15rem !important; */
    /* margin-right: 3rem !important; */
  }
  .xcn-user-profile{
      width: 70px;
      height: 70px;
      border-radius: 50%;
  }